import { useContext } from 'react'
import { List as UnorderedList, OrderedList } from '@telus-uds/components-web'
import { getIconSize } from '../List/List'
import { getIcon } from '../../../utils/getIcon'
import { IconColor, iconColors, IconSize, ListSize } from './TypographyVariantTypes'
import React from 'react'
import styled from 'styled-components'
import { NestedList } from './NestedList'
import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'

// This is to resolve an issue where the unordered list text wad not aligning with the bullets on the left
const ListItemContentContainer = styled.div<RichTextListProps>`
  align-self: ${(props) => (props.type === 'ul' ? 'flex-start' : 'auto')};
`

export const ParagraphSizeToListSize = {
  micro: 'small',
  small: 'small',
  large: 'large',
  eyebrow: 'large',
}

// Lists should match text, so extra small should be allowed. Waiting UDS Fix
export type RichTextListProps = {
  type: 'ol' | 'ul'
  size?: ListSize
  iconName?: string
  iconColor?: IconColor
  iconSize?: IconSize
  children: React.ReactNode
}

const listTypes = { ol: OrderedList, ul: UnorderedList }

const RichTextList = ({
  children,
  type,
  size,
  iconName,
  iconColor = 'purpleTelus',
  iconSize = 'medium',
}: RichTextListProps) => {
  const { brand } = useContext(SiteBuilderContext)
  if (!children) return

  const List = listTypes[type]

  const IconComponent = type === 'ul' && iconName ? getIcon(iconName, brand) : undefined

  const iconProps = !!IconComponent
    ? {
        icon: IconComponent,
        iconColor: iconColors[iconColor],
        iconSize: getIconSize(iconSize),
      }
    : null

  let items

  if (Array.isArray(children)) {
    items = children.flatMap((item: React.ReactNode, i) => {
      return (
        Array.isArray(item) &&
        item.map((child: React.ReactNode, j) => {
          if (!child) return null
          // TODO: replace this when nested lists are allow in UDS
          if (React.isValidElement<RichTextListProps>(child) && child.type === RichTextList)
            return <NestedList key={`nested-item-${i}`}>{child}</NestedList>

          return (
            <List.Item key={`list-item-${i}-text-${j}`} {...iconProps}>
              <ListItemContentContainer type={type}>{child}</ListItemContentContainer>
            </List.Item>
          )
        })
      )
    })
  }

  return <List variant={{ size }}>{items}</List>
}

RichTextList.displayName = 'RichTextList'

export default RichTextList
